<template>
  <div>
    <el-cascader
        ref="deptCascaderScope"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        placeholder="搜索：项目*部 / 项目*组"
        :options="options"
        :props="{ value: 'dpt_id',label:'name',children:'groups',multiple: multiple,checkStrictly:checkStrictly }"
        filterable clearable @change="handleDeptLabels"></el-cascader>
  </div>
</template>

<script>

export default {
  name: "DeptCascaderScope",
  props: {
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentGroups: {
      type: [Array, String],
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: [String, Object],
      default: ''//revenue_data revenue_enter trend_data trend_enter
    }
    ,
    selectUrl: {
      type: String,
      default() {
        return 'getMcnGroupDeptOption'
      }
    },
    checkStrictly: {
      type: Boolean,
      default: false

    }
  },
  data() {
    return {
      value: null,
      dpt_id: null,
      options: [],
      scopeGroups: [],
      range: {dpt_range: [], my_dpt_range: []},
    };
  },
  watch: {
    currentGroups( val, oldVal ) {
      if (val !== oldVal)
        this.value = val
    },
  },
  methods: {
    async getOptions() {
      this.options = []
      let data= await this.$api[this.selectUrl](this.type)
      this.$nextTick(() => {
        this.options = data?.list ? data.list : data
        if (this.showFirstGroup) {
          this.firstGroup()
        }
      })
    },
    firstGroup() {
      if (this.options && this.options.length > 0) {
        if (this.options[0]['groups']) {
          let group = this.options[0]['groups'] ? this.options[0]['groups'][0] : {}
          this.$emit('firstGroup', group)
          this.value = group.dpt_id
        }
      }
    },
    initVal( val ) {
      this.value = val
    },
    //获取label值
    handleDeptLabels( valArr ) {
      let labelArr = []
      if (valArr && valArr.length > 0) {
        if (this.multiple) {
          let nodes = this.$refs['deptCascaderScope'].getCheckedNodes()
          nodes.forEach(( nodeObj ) => {
            labelArr.push(nodeObj.pathLabels)
          })
        } else
          labelArr = this.$refs['deptCascaderScope'].getCheckedNodes()[0].pathLabels

      }
      this.$emit('selectedDept', valArr, labelArr)
    },

  },
  mounted() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>
